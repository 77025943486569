// Generated by Framer (2923eb5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["mGQlPT4EX", "vS8naJ766", "aVVMhG7pj"];

const serializationHash = "framer-pe7Q8"

const variantClassNames = {aVVMhG7pj: "framer-v-165jcks", mGQlPT4EX: "framer-v-1q8rzi8", vS8naJ766: "framer-v-1p9c743"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "mGQlPT4EX", Phone: "vS8naJ766", widescreen: "aVVMhG7pj"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "mGQlPT4EX"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "mGQlPT4EX", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1q8rzi8", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"mGQlPT4EX"} ref={ref ?? ref1} style={{backgroundColor: "rgb(51, 170, 255)", ...style}} {...addPropertyOverrides({aVVMhG7pj: {"data-framer-name": "widescreen"}, vS8naJ766: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pe7Q8.framer-in3cs5, .framer-pe7Q8 .framer-in3cs5 { display: block; }", ".framer-pe7Q8.framer-1q8rzi8 { height: 125px; overflow: hidden; position: relative; width: 1064px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 125
 * @framerIntrinsicWidth 1064
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"vS8naJ766":{"layout":["fixed","fixed"]},"aVVMhG7pj":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRTkQbPhHb: React.ComponentType<Props> = withCSS(Component, css, "framer-pe7Q8") as typeof Component;
export default FramerRTkQbPhHb;

FramerRTkQbPhHb.displayName = "footer";

FramerRTkQbPhHb.defaultProps = {height: 125, width: 1064};

addPropertyControls(FramerRTkQbPhHb, {variant: {options: ["mGQlPT4EX", "vS8naJ766", "aVVMhG7pj"], optionTitles: ["Desktop", "Phone", "widescreen"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerRTkQbPhHb, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})